<template>
  <div v-if="!pending" class="panel-follow-suggestions">
    <Panel v-if="suggestedList.items.length" label="Suggestions" :collapsible="true">
      <div
        v-for="creator in suggestedList.items"
        :key="creator.id"
        class="flex flex-col gap-2"
      >
        <SuggestionUserCard
          :creator="creator"
          @creator-followed="updateSuggestedList"
        />
      </div>
      <DotPagination
        :theme="theme"
        :current-page="currentPageNumber"
        :next-page="creators.pagination.nextPage"
        :per-page="perPage"
        :previous-page="creators.pagination.previousPage"
        :total-items="creators.pagination.totalItems"
        :total-pages="creators.pagination.totalPages"
        @next="next"
        @previous="previous"
        @go-to-page="goToPage"
      />
    </Panel>
  </div>
</template>

<script setup>
  const runtimeConfig = useRuntimeConfig()
  const perPage = ref(3)
  const currentPageNumber = ref(1)
  const theme = "micro"

  const { pending, data: creators } = await useAuthFetch(
    runtimeConfig.public.API_URL + "/api/suggestions",
    {
      lazy: false,
      server: false,
      query: {
        page: currentPageNumber,
        per_page: perPage,
      },
    },
  )

  let suggestedList = ref(creators)

  const next = () => {
    currentPageNumber.value += 1
  }

  const previous = () => {
    currentPageNumber.value -= 1
  }

  const goToPage = (number) => {
    currentPageNumber.value = number
  }

  const updateSuggestedList = async () => {
    const { pending, data: creators } = await useAuthFetch(
      runtimeConfig.public.API_URL + "/api/suggestions",
      {
        lazy: false,
        server: false,
        query: {
          page: currentPageNumber,
          per_page: perPage,
        },
      },
    )
    suggestedList = creators
  }
</script>

<style lang="scss" scoped>
  .panel-follow-suggestions {
    @apply w-full;
  }
</style>