<template>
  <!-- Form -->
  <PostCommentForm :postId="props.postId" @comment-created="refreshComments" />

  <UDivider class="mt-2 py-2" />

  <div v-if="!commentsPending" class="animate-fade-down">
    <div v-if="!commentsPending && comments.items.length === 0" class="text-[#595858] dark:text-[#555F63]">
      No comments yet.
    </div>

    <!-- Feed -->
    <div id="comment-feed" v-if="!commentsPending" v-for="c in comments.items" class="flex flex-col gap-3">
      <PostComment :comment="c" @comment-liked="handleCommentLiked" @comment-deleted="handleCommentDeleted"
        @comment-reply-added="handleCommentReply" @comment-unliked="handleCommentUnliked"
        @comment-complaint="handleCommentComplaint" />
    </div>

    <!-- View More -->
    <div v-if="!commentsPending && comments.pagination.totalItems > 3 && props.mode === 'compact'"
      class="italic text-center text-[#595858] dark:text-[#555F63] hover:text-pink-600 dark:hover:text-pink-500 mb-8 cursor-pointer"
      @click="handleViewMoreComments">
      <NuxtLink :to="'/' + creator.username + '/posts/' + postId" class="text-xxs sm:text-sm whitespace-nowrap">View
        more comments</NuxtLink>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  postId: {
    type: String,
    required: true
  },
  creator: {
    type: Object,
    required: true
  },
  mode: {
    type: String,
    default: 'compact' // 'full'
  }
})

const config = useRuntimeConfig()

const perPage = computed(() => {
  return props.mode === 'full' ? 20 : 3
})

const { pending: commentsPending, data: comments, refresh: refreshComments } = await useAuthFetch(
  config.public.API_URL + `/api/posts/${props.postId}/comments?per_page=${perPage.value}`,
  { method: "GET" }
)

const handleViewMoreComments = async () => {
  console.log('View more comments')
}

const handleCommentDeleted = () => {
  console.log('Comment deleted')
  refreshComments()
}

const handleCommentReply = () => {
  console.log('Comment replied')
  refreshComments()
}

const handleCommentLiked = () => {
  console.log('Comment liked')
}

const handleCommentUnliked = () => {
  console.log('Comment unliked')
}

const handleComplaint = () => {
  console.log('Comment reported')
}
</script>