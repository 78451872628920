<template>
  <div @click="toggleLike(resourceId)" title="Like Post">
    <font-awesome-icon
      v-if="isLiked"
      icon="fa-solid fa-heart"
      :size="size"
      class="hover-pink"
    />
    <font-awesome-icon
      v-else
      icon="fa-light fa-heart"
      :size="size"
      class="hover-pink"
    />
  </div>
</template>

<script setup>
  import { useUserStore } from "@/store/user"

  const loginModalOpen  = inject("loginModalOpen")
  const userStore       = useUserStore()
  const config          = useRuntimeConfig()

  const props = defineProps({
    size: {
      type: String,
      default: "lg",
    },
    liked: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: String, // post or comment
      required: true,
    },
    resourceId: {
      type: String,
      required: true,
    },
  })

  const emit = defineEmits(["liked"])

  const isLiked = ref(props.liked)

  const toggleLike = async () => {
    if (!userStore.loggedIn) {
      loginModalOpen.value = true
    } else {
      const endpoint = `${config.public.API_URL}/api/${props.resource}s/${props.resourceId}/like`
      const method = isLiked.value ? "DELETE" : "POST"

      try {
        await $api(endpoint, { method: method })
        isLiked.value = !isLiked.value
        emit("post-liked", isLiked.value)
      } catch (error) {
        const toast = useToast()
        const errors = useErrorMessage(error.response._data)
        toast.add({ title: errors, color: "red" })
      }
    }
  }
</script>
