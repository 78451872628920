<template>
  <div :id="post.id" class="post-component animate-fade">
    <div class="container">
      <Panel>
        <PostHeading :postId="post.id" :creator="post.creator" :createdAt="post.createdAt" :contextMenu="contextMenu"
          :override="overrideLock" @post-deleted="handlePostDeleted" @post-reported="handlePostReported"
          @post-edit="handlePostEdit" />
        <PostBody :body="post.text" />
        <PostStats :stats="stats" :release="post.postedAt" :override="overrideLock" />
        <PostGallery :postId="post.id" :media="post.media" :creator="post.creator" :locked="post.locked"
          :preview="isLocked || preview" :overrideLock="overrideLock" @post-purchase="handlePostPurchase"
          :date="post.postedAt" />
        <PostActions :post="post" :preview="preview" @post-purchase="handlePostPurchase" @post-liked="handlePostLiked"
          @post-tip="handlePostTip" @toggle-comments="showComments = !showComments" />

        <div v-if="showComments" class="w-full mt-[-20px]">
          <PostCommentFeed :postId="post.id" :creator="post.creator" :mode="commentMode" />
        </div>
      </Panel>
    </div>
  </div>
</template>

<script>
export default { name: 'Post' }
</script>

<script setup>
/******************************************************
 *   	Setup Define
 *****************************************************/
import { useUserStore } from "@/store/user"

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  preview: {
    type: Boolean,
    default: false,
  },
  contextMenu: {
    type: Boolean,
    default: false
  },
  overrideLock: {
    type: Boolean,
    default: false,
  }
})

const emit = defineEmits([
  "post-purchased",
  "post-liked",
  "post-deleted",
  "post-reported",
  "post-tip",
  "comment-created",
  "comment-liked",
  "comment-deleted",
  "comment-reply",
])

/******************************************************
 *   	Composables, Refs & Variables
 *****************************************************/
const tokenPurchaseModalOpen = inject("tokenPurchaseModalOpen")
const loginModalOpen = inject('loginModalOpen')

const config = useRuntimeConfig()
const toast = useToast()
const userStore = useUserStore()

const isLocked = ref(props.post.locked)
const showComments = ref(!props.preview)

/******************************************************
 *   	Computed Functions
 *****************************************************/
const commentMode = computed(() => {
  return props.preview ? "compact" : "full"
})

const stats = computed(() => {
  return {
    media: {
      imageCount: props.post.media.filter((item) => item.type == "image").length,
      videoCount: props.post.media.filter((item) => item.type == "video").length,
    },
    reactions: {
      likeCount: props.post.totalLikes,
      commentCount: props.post.totalComments,
    },
    price: {
      tokenCount: props.post.tokenCount,
      isFree: props.post.tokenCount == 0,
    },
  }
})

/******************************************************
 *   	Event Handlers
 *****************************************************/
const handlePostEdit = (id) => {
  emit("post-edit", id)
}

const handlePostDeleted = (id) => {
  emit("post-deleted", id)
}

const handlePostReported = (id) => {
  emit("post-reported", id)
}

const handlePostPurchase = async (postId) => {
  console.log('------> Post handlePostPurchase', postId)

  if (!userStore.loggedIn) {
    loginModalOpen.value = true
    return
  } else {
    if (userStore.tokenBalance < props.post.tokenCount) {
      toast.add({ title: "You don't have enough tokens to unlock this post." })
      tokenPurchaseModalOpen.value = true
    } else {
      try {
        const updatedPost = await $api(`${config.public.API_URL}/api/posts/${postId}/unlock`, { method: "POST" })
        userStore.getData()
        toast.add({ title: "You've unlocked a post!" })
        emit("post-purchased", updatedPost)
      } catch (error) {
        toast.add({
          title: useErrorMessage(error.response._data),
          color: "red",
        })
      }
    }
  }
}

const handlePostLiked = (id, value) => {
  emit("post-liked", id, value)
}

const handlePostTip = (value) => {
  emit("post-tip", value)
}

const handleCommentCreated = (comment) => {
  emit("comment-created", comment)
}

const handleCommentLiked = (value) => {
  emit("comment-liked", value)
}

const handleCommentDeleted = (id) => {
  emit("comment-deleted", id)
}

const handleCommentReply = (id) => {
  emit("comment-reply", id)
}
</script>

<style lang="scss" scoped>
.post-component {
  @apply w-full text-sm;
}

.container {
  @apply w-full flex flex-col gap-2 justify-start items-start;
}
</style>
