<template>
  <div id="comment-reply-form">
    <UForm :schema="schema" :state="replyForm.data" @submit="handleSubmit">
      <FormGroup label="&nbsp;">
        <UInput ref="input" v-model="replyForm.data.text" size="lg" :ui="inputStyles" variant="none"
          placeholder="Share your thoughts..." :keyWords="['@russ']" @input="handleInput">
          <template #leading>
            <UPopover :popper="{ arrow: true, placement: 'right' }">
              <font-awesome-icon icon="fa-light fa-face-smile"
                class="text-lg text-pink-600 dark:text-gray-100/75 rounded-full p-2 mt-[6px] hover:bg-cool-200 hover:dark:bg-[#2B3236]" />
              <template #panel>
                <div class="flex flex-row items-center gap-2 p-4">
                  <a class="transition transition-all hover:text-2xl hover:cursor-pointer"
                    @click="handleEmojiSelect('❤️')">❤️</a>
                  <a class="transition transition-all hover:text-2xl hover:cursor-pointer"
                    @click="handleEmojiSelect('👍')">👍</a>
                  <a class="transition transition-all hover:text-2xl hover:cursor-pointer"
                    @click="handleEmojiSelect('😘')">😘</a>
                  <a class="transition transition-all hover:text-2xl hover:cursor-pointer"
                    @click="handleEmojiSelect('🤯')">🤯</a>
                  <a class="transition transition-all hover:text-2xl hover:cursor-pointer"
                    @click="handleEmojiSelect('🥵')">🥵</a>
                </div>
              </template>
            </UPopover>
          </template>
          <template #trailing>
            <Button theme="secondary" :iconOnly="true" size="sm" @click="handleSubmit">
              <font-awesome-icon icon="fa-solid fa-location-arrow-up" class="fa-rotate-90" size="md" />
            </Button>
          </template>
        </UInput>
      </FormGroup>
    </UForm>
  </div>
</template>

<script setup>
import { object, string, number } from 'yup'

const props = defineProps({
  postId: {
    type: String,
    required: true
  },
  parentId: {
    type: String,
    required: true
  }
})

const emit = defineEmits([
  "reply-created"
])

const userStore = useUserStore()
const config = useRuntimeConfig()
const toast = useToast()
const showReplies = ref(false)
const input = ref(null)

const replyForm = reactive({
  data: {
    text: ''
  }
})

const schema = object({
  text: string().required().max(140)
})

const commentLength = computed(() => {
  return `${replyForm.data.text.length} / 2000 Characters`
})

const highlightMentions = (text) => {
  const regex = /\B@\w+/g
  return text.replace(regex, function (match) {
    let username = match.trim().substring(1)
    return `<a href="/${username}" class="text-pink-500">${match}</a>`
  })
}

const isCommentOwner = (author) => {
  return author.username === userStore.username
}

const handleInput = (value) => {
  if (replyForm.data.text && replyForm.data.text.includes('@')) {
    // TODO: Implement mention suggestions
    console.log('Caught possible mention', replyForm.data.text.match(/\B@\w+/g))
  }
}

const handleSubmit = async () => {
  if (!replyForm.data.text.length) {
    toast.add({ title: 'Reply cannot be empty', type: 'error' })
    return
  }

  if (userStore.loggedIn) {
    try {
      await $api(
        config.public.API_URL + `/api/posts/${props.postId}/comments`,
        {
          method: 'POST',
          body: {
            comment: {
              postId: props.postId,
              text: replyForm.data.text,
              commentId: props.parentId
            }
          }
        }
      )

      replyForm.data.text = ''
      emit('reply-created')
    } catch (err) {
      toast.add({ title: useErrorMessage(err.response._data), type: 'error' })
    }
  } else {
    await navigateTo('/join')
  }
  console.log('Reply submitted')
}

const handleEmojiSelect = (emoji) => {
  replyForm.data.text = replyForm.data.text + emoji
  input.value.input.focus()
  input.value.input.select()
}

const inputStyles = {
  variant: {
    none: 'font-semibold ring-1 ' +
      'focus:ring-1 focus:ring-[#f26998] ' +
      'dark:ring-[#384247] dark:bg-[#1b2022] ' +
      'dark:focus:ring-[#f26998] dark:focus:bg-[#2b3236]'
  },
  icon: {
    leading: {
      pointer: '',
      padding: {
        lg: 'px-1'
      }
    },
    trailing: {
      pointer: ''
    }
  }
}
</script>