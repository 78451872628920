import confetti from "canvas-confetti"

export function useShootHearts() {
  const heart = confetti.shapeFromPath({
    path: 'M167 72c19,-38 37,-56 75,-56 42,0 76,33 76,75 0,76 -76,151 -151,227 -76,-76 -151,-151 -151,-227 0,-42 33,-75 75,-75 38,0 57,18 76,56z',
    matrix: [0.03333333333333333, 0, 0, 0.03333333333333333, -5.566666666666666, -5.533333333333333]
  })
  
  var count = 200;
  var defaults = {
    angle: 97,
    origin: { y: 1, x: 0.7 },
    drift: -0.5,
    gravity: 0.3
  };
  
  function fire(particleRatio, opts) {
    confetti({
      ...defaults,
      ...opts,
      particleCount: Math.floor(count * particleRatio),
      shapes: [heart],
      colors: ['#EA1662','#F2699B','#F8A5C2','#F8A5C2','#FFFFFF','#000000']
    });
  }
  
  fire(0.25, {
    spread: 36,
    startVelocity: 65,
    scalar: 2
  })
  fire(0.2, {
    spread: 60,
    scalar: 0.8
  })
  fire(0.1, {
    spread: 20,
    startVelocity: 65,
    scalar: 1
  })
}
