<template>
  <NuxtLink
    :to="`/${creator.username}`"
    v-if="backgroundUrlIsVideo"
    class="relative w-full h-[116px] rounded-lg bg-cover bg-black bg-center bg-no-repeat"
  >
    <video
      class="absolute top-0 left-0 w-full h-[116px] rounded-lg bg-cover object-cover bg-center bg-no-repeat"
      autoplay
      loop
      muted
      playsinline
      :src="creator.backgroundUrl"
    />

    <div
      class="z-2 absolute h-full inset-0 p-2 rounded-lg overflow-hidden bg-black bg-opacity-60 flex flex-col items-center justify-end transition-all group-hover:bg-opacity-35"
    >
      <div class="w-full flex items-center justify-between">
        <IdentityCard size="xs" :user="creator" />
        <FollowButton
          size="xs"
          :following="creator"
          @creator-followed="broadcastFollow"
        />
      </div>
    </div>
  </NuxtLink>

  <NuxtLink
    :to="`/${creator.username}`"
    v-else
    class="relative w-full h-[116px] rounded-lg bg-cover bg-center bg-no-repeat"
    :style="`background-image: url('${creator.backgroundUrl}')`"
  >
    <div
      class="absolute h-full inset-0 p-2 rounded-lg overflow-hidden bg-black bg-opacity-60 flex flex-col items-center justify-end transition-all group-hover:bg-opacity-35"
    >
      <div class="w-full flex items-center justify-between">
        <IdentityCard size="xs" :user="creator" />
        <FollowButton
          size="xs"
          :following="creator"
          @creator-followed="broadcastFollow"
        />
      </div>
    </div>
  </NuxtLink>
</template>

<script setup>
  import { useUserStore } from "@/store/user"

  const props = defineProps({
    creator: {
      type: Object,
      required: true,
    },
  })

  const emit = defineEmits(["creator-followed"])
  const userStore = useUserStore()

  const broadcastFollow = (creator) => {
    emit("creator-followed", creator)
  }

  const user = computed(() => {
    if (props.creator) {
      return props.creator
    } else {
      return userStore
    }
  })

  const backgroundUrlIsVideo = computed(() => {
    return props.creator?.backgroundUrl.includes(".mp4")
  })
</script>
