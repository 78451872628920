<template>
  <div class="post-actions">
    <PostUnlockButton :post="post" @post-purchase="handlePostPurchase" />
    <div class="controls">
      <div class="left">
        <PostLikeButton class="text-sm sm:text-base" :liked="liked" resource="post" :resourceId="post.id"
          @post-liked="handlePostLiked" />

        <Button v-if="preview" theme="ghost" :iconOnly="true" size="xs" @click="$emit('toggle-comments')"
          title="View Comments">
          <font-awesome-icon icon="fa-light fa-comment" size="xl" class="fa-fw" />
        </Button>

        <PostTipButton v-if="!isViewingOwnPost" :creator="post.creator" :post="post" @post-tipped="handlePostTipped" />
      </div>
      <ul class="right">
        <li>
          <NuxtLink :to="'/' + post.creator.username + '/posts/' + post.id"
            class="text-xxs sm:text-sm whitespace-nowrap">
            <span class="hidden sm:block">
              {{ $numberToHuman(likes) }} likes
            </span>
            <span class="block sm:hidden">
              {{ $numberToHuman(likes) }}
              <font-awesome-icon icon="fa-solid fa-heart" class="text-primary ml-1" />
            </span>
          </NuxtLink>
        </li>
        <li>
          <NuxtLink :to="'/' + post.creator.username + '/posts/' + post.id"
            class="text-xxs sm:text-sm whitespace-nowrap">
            <span class="hidden sm:block">
              {{ $numberToHuman(comments) }} comments
            </span>
            <span class="block sm:hidden">
              {{ $numberToHuman(comments) }}
              <font-awesome-icon icon="fa-solid fa-comment" class="text-primary ml-1" />
            </span>
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
/******************************************************
 *   	Imports
 *****************************************************/
import { useUserStore } from "@/store/user"

/******************************************************
 *   	Setup Define
 *****************************************************/
const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  preview: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits([
  "post-purchase",
  "post-liked",
  "post-tipped",
  "toggle-comments"
])
/******************************************************
 *   	Composables, Refs & Variables
 *****************************************************/
const userStore = useUserStore()

const postLocked = ref(props.post.locked)
const postCost = ref(props.post.tokenCount)
const liked = ref(props.post.likedByUser)
const likes = ref(props.post.totalLikes)
const comments = ref(props.post.totalComments)

/******************************************************
 *   	Computed Functions
 *****************************************************/
const isViewingOwnPost = computed(
  () => userStore.username === props.post.creator.username,
)

/******************************************************
 *   	Event Handlers
 *****************************************************/
const handlePostLiked = (value) => {
  emit("post-liked", props.post.id, value)
  liked.value = value
  likes.value = liked.value ? ++likes.value : --likes.value
}

const handlePostPurchase = (postId) => {
  emit("post-purchase", postId)
  postLocked.value = false
}

const handlePostTipped = (value) => {
  emit("post-tipped", props.post.id, value)
}
</script>

<style lang="scss" scoped>
.post-actions {
  @apply w-full flex flex-col items-start justify-center gap-2 sm:gap-4;
}

.controls {
  @apply w-full flex flex-row items-center justify-between;
}

.left {
  @apply flex flex-row items-center justify-start gap-2;
}

.right {
  @apply flex flex-row items-center sm:list-disc justify-end items-end text-right text-xs;
}

ul {
  li:first-child {
    @apply list-none;
  }

  li {
    @apply pr-6;
  }

  li:last-child {
    @apply pr-0;
  }
}
</style>
